<template>
  <div class="h-100" :class="{ black: !themeColor, light: !themeType, [themeColor]: true, [themeType]: true }">
    <!-- Poll -->
    <div v-if="isPoll" class="poll px-h">
      <h3 class="poll__question form__heading">
        {{ adData.question }}
      </h3>

      <ul v-if="adData.poll_variants.length > 0" class="poll__list">
        <li v-for="(variant, key) in adData.poll_variants" :key="key" class="poll__list-item">
          <label class="poll__input-group" role="button">
            <input
              v-model="pickedVariant"
              type="radio"
              name="poll"
              :value="variant"
              class="poll__input-element visually-hidden"
            />
            <!-- eslint-disable-next-line -->
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22" class="poll__radio-icon">
              <circle fill="#FFF" cx="11" cy="11" r="10" stroke="currentColor" stroke-width="2" />
              <circle
                cx="11"
                cy="11"
                r="5"
                fill="currentColor"
                class="poll__radio-dot"
                :class="{ 'poll__radio-dot--selected': pickedVariant === variant }"
              />
            </svg>
            <span class="poll__question-variant">{{ variant }}</span>
          </label>
        </li>
        <li v-if="adData.self_variant" class="poll__list-item">
          <label class="poll__input-group" role="button">
            <input
              v-model="pickedVariant"
              type="radio"
              name="poll"
              :value="SELF_VARIANT"
              class="poll__input-element visually-hidden"
            />
            <!-- eslint-disable-next-line -->
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22" class="poll__radio-icon">
              <circle fill="#FFF" cx="11" cy="11" r="10" stroke="currentColor" stroke-width="2" />
              <circle
                cx="11"
                cy="11"
                r="5"
                fill="currentColor"
                class="poll__radio-dot"
                :class="{ 'poll__radio-dot--selected': pickedVariant === SELF_VARIANT }"
              />
            </svg>
            <div class="poll__self-variant-container">
              <span class="poll__question-variant">{{ $t('adsprofile.PollSelfVariant') }}</span>

              <textarea
                v-if="pickedVariant === SELF_VARIANT"
                :id="SELF_VARIANT"
                v-model="selfVariantAnswer"
                class="poll__self-variant-text"
                :name="SELF_VARIANT"
                rows="3"
                :placeholder="$t('adsprofile.pollSelfVariantPlaceholder')"
              ></textarea>
            </div>
          </label>
        </li>
      </ul>

      <ul v-if="adData.poll_image.length > 0" class="poll__list">
        <li v-for="(variant, key) in adData.poll_image" :key="key" class="poll__list-item">
          <label
            class="poll__input-group"
            :class="{ 'poll__input-group--selected': pickedIndex === key }"
            @click="pickedIndex = key"
          >
            <input
              v-model="pickedVariant"
              type="radio"
              name="poll"
              :value="variant.text"
              class="poll__input-element visually-hidden"
            />
            <span class="poll__question-image" :style="`background-image: url('${variant.image}')`" />
            <span
              class="poll__question-variant"
              :class="{ 'poll__question-with-img-variant--selected': pickedIndex === key }"
            >
              {{ variant.text }}
            </span>
          </label>
        </li>
      </ul>

      <button
        type="submit"
        class="button button--size-full button--action-form form__button"
        :disabled="!isReadyToSubmit"
      >
        <span>{{ $t('general.next') }}</span>
      </button>
    </div>

    <!-- Poll Quality -->
    <div v-if="isPollQuality" class="poll-quality-ad px-h">
      <h3 class="poll-quality-ad__question form__heading">
        {{ adData.question }}
      </h3>
      <poll-quality-ad-smile
        :rating="pickedVariant"
        :max-rating="adData.poll_variants.length"
        :key="ad.id + '-' + pickedVariant"
      />
      <star-rating
        v-if="adData.poll_variants.length > 0 && (themeType === 'light' || themeType === '')"
        v-model="pickedVariant"
        class="stars-container"
        :max-rating="adData.poll_variants.length"
        inactive-color="#EEEEEE"
        :active-color="themeColorHex"
        :show-rating="false"
      />
      <star-rating
        v-if="adData.poll_variants.length > 0 && themeType === 'contrast'"
        v-model="pickedVariant"
        class="stars-container"
        :max-rating="adData.poll_variants.length"
        inactive-color="#2c313c"
        active-color="#EEEEEE"
        :show-rating="false"
      />
      <button
        type="submit"
        class="button button--size-full button--action-form form__button"
        :disabled="!isReadyToSubmit"
      >
        <span>{{ $t('general.next') }}</span>
      </button>
    </div>

    <!-- Poll User Data -->
    <div v-if="isPollUserData" class="poll px-h">
      <!--  -->
      <div v-if="adData.poll_fields.sex">
        <h3 class="poll__question form__heading">
          {{ $t('adsprofile.pollUserData.sex') }}
        </h3>
        <ul class="poll__list">
          <li v-for="(variant, key) in genders" :key="key" class="poll__list-item">
            <label class="poll__input-group">
              <input
                v-model="pickedVariant"
                type="radio"
                name="poll"
                :value="variant"
                class="poll__input-element visually-hidden"
              />
              <!-- eslint-disable-next-line -->
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22" class="poll__radio-icon">
                <circle fill="#FFF" cx="11" cy="11" r="10" stroke="currentColor" stroke-width="2" />
                <circle
                  cx="11"
                  cy="11"
                  r="5"
                  fill="currentColor"
                  class="poll__radio-dot"
                  :class="{ 'poll__radio-dot--selected': pickedVariant === variant }"
                />
              </svg>
              <span class="poll__question-variant">{{ $t(`adsprofile.pollUserData.genders.${variant}`) }}</span>
            </label>
          </li>
        </ul>
      </div>

      <div v-if="adData.poll_fields.date_of_birth">
        <h3 class="poll__question form__heading">
          {{ $t('adsprofile.pollUserData.dateOfBirth') }}
        </h3>
        <div class="poll__list w-100">
          <input v-model="selectedDate" type="date" class="field form__input w-100" />
          <!-- eslint-disable-next-line -->
        </div>
      </div>

      <button
        v-if="adData.poll_fields.sex || adData.poll_fields.date_of_birth"
        type="submit"
        class="button button--size-full button--action-form form__button"
        :disabled="!isReadyToSubmit"
      >
        <span>{{ $t('general.next') }}</span>
      </button>
    </div>
  </div>
</template>
<script>
import StarRating from 'vue-star-rating';
import PollQualityAdSmile from './poll-quality-ad-smile.vue';

export default {
  name: 'PortalPollPreview',
  components: {
    StarRating,
    PollQualityAdSmile
  },
  data() {
    return {
      SELF_VARIANT: 'self_variant',
      selfVariantAnswer: '',
      showSpinner: false,
      pickedVariant: null,
      pickedIndex: -1,
      validationErrors: [],
      genders: ['female', 'male'],
      selectedDate: ''
    };
  },
  props: {
    type: { type: String, default: 'poll' },
    ad: {
      type: Object,
      default() {
        return {};
      }
    },
    themeColor: { type: String, default: '' },
    themeType: { type: String, default: '' }
  },
  computed: {
    adData() {
      return this.ad.data;
    },
    isPollUserData() {
      return this.adData.type === 'poll_user_data';
    },
    isPoll() {
      return this.adData.type === 'poll';
    },
    isPollQuality() {
      return this.adData.type === 'poll_quality';
    },
    themeColorHex() {
      if (this.themeType === 'light' || this.themeType === 'contrast') {
        switch (this.themeColor) {
          case 'gray':
            return '#616161';
          case 'black':
            return '#212121';
          case 'brown':
            return '#795548';
          case 'darkred':
            return '#BF360C';
          case 'red':
            return '#E64919';
          case 'orange':
            return '#EF6C00';
          case 'yellow':
            return '#FFC400';
          case 'green':
            return '#43A047';
          case 'teal':
            return '#00BFA5';
          case 'darkblue':
            return '#1976D2';
          case 'blue':
            return '#039BE5';
          case 'purple':
            return '#673AB7';
          case 'pink':
            return '#D81B60';
          case 'violent-pink':
            return '#FF4081';
          default:
            return '';
        }
      }
      return '';
    },
    isReadyToSubmit() {
      return this.pickedVariant !== null || this.selectedDate;
    }
  },
  created() {
    if (this.adData.type === 'poll_quality') {
      const maxStars = this.adData.poll_variants.length;
      this.pickedVariant = Math.round(maxStars / 2 + Math.random() * (maxStars / 2));
    }
  },
  watch: {
    'adData.poll_variants': {
      deep: true,
      handler() {
        if (this.adData.type === 'poll_quality') {
          const maxStars = this.adData.poll_variants.length;
          this.pickedVariant = Math.round(maxStars / 2 + Math.random() * (maxStars / 2));
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import './palettes.scss';

.poll {
  --text-size-l: 10px;
  --text-size-xl: 14px;
  padding: 20px 0;
  box-sizing: border-box;
  background-image: -webkit-linear-gradient(bottom, var(--color-bg) 0%, #fff 120%);
  height: 100%;
}

.poll__list {
  margin: 0;
  padding: 0;

  list-style: none;
}

.poll__list-item {
  text-align: left;
}

.poll__list-item + .poll__list-item {
  margin-top: 7px;
}

.poll__input-group {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px;

  font-size: var(--text-size-l);

  background-color: var(--form-color-field-bg);
  border: 1px solid var(--form-color-border);
  border-radius: 4px;
}

.poll__input-group--selected {
  color: var(--form-color-button-text);

  background-color: var(--form-color-focus);
  border-color: var(--form-color-focus);
}

.poll__radio-icon {
  width: 1em;
  height: 1em;
  margin-right: 10px;
  flex-shrink: 0;

  color: var(--color-main);
}

.poll__radio-dot {
  color: #ffffff;
}

.poll__radio-dot--selected {
  color: var(--form-color-radio);
}

.poll__question-image {
  width: 70px;
  height: 70px;
  flex-shrink: 0;
  margin-right: 10px;
  font-size: var(text-size-xl);

  border: 1px solid var(--form-color-border);
  border-radius: 50%;
  background-size: cover;
  background-position: 50%;
  background-repeat: no-repeat;
}
.poll__question-variant {
  color: var(--color-text-default);
}
.poll__question-with-img-variant--selected {
  color: inherit !important;
}
.poll__self-variant-container {
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
}
.poll__self-variant-text {
  margin-top: 1rem;
  border-radius: 4px;
  border: 1px solid var(--form-color-border);
  resize: none;
  width: 100%;
  padding: 2px 4px;
}

.visually-hidden:not(:focus):not(:active),
input[type='checkbox'].visually-hidden,
input[type='radio'].visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  -webkit-clip-path: inset(100%);
  clip-path: inset(100%);
  font-size: 1px;
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.form__heading {
  display: block;
  margin: 0 0 26px;
  text-align: center;

  font-weight: bold;
  font-size: var(--text-size-xl);
  letter-spacing: -0.4px;
  color: var(--form-color-heading);
  white-space: pre-line;
}

.button {
  position: relative;

  /* width: 100%; */
  padding: 0 20px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 3px;
  border: 0;
  background-color: var(--color-main);

  color: var(--color-text-contrast);
  font-size: var(--text-size-l);
  font-weight: 700;
  text-align: center;
  text-decoration: none;

  transition: background 0.2s;

  cursor: pointer;
  -webkit-touch-callout: none;
  user-select: none;
}

.button:hover:not(:disabled),
.button:focus {
  background-color: var(--color-light);
}

.button:active {
  background-color: var(--color-dark);
}

.button:disabled {
  cursor: not-allowed;
  color: var(--color-disabled-main);
  background-color: var(--color-disabled-bg);
}

.button--action-form {
  height: 35px;
}
.button--size-full {
  width: 100%;
}
.form__button {
  margin-top: 12px;
}
.poll-quality-ad {
  --text-size-l: 10px;
  --text-size-xl: 14px;
  background-image: -webkit-linear-gradient(bottom, var(--color-bg) 0%, #fff 120%);
  padding: 20px 0;
  box-sizing: border-box;
  height: 100%;
}

.poll-quality-ad__list {
  display: flex;
  flex-flow: row nowrap;
}

.stars-container {
  margin: 30px auto;
  width: fit-content;
  display: flex;
  flex-flow: row wrap;
}

.poll-user-data {
  padding: 20px 0;
}
</style>
