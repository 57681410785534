<template>
  <div class="smile-container">
    <div v-if="whichSmileShown === 1" class="smile">
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 473.935 473.935"
        style="enable-background: new 0 0 473.935 473.935;"
        xml:space="preserve"
      >
        <circle style="fill: #ffc10e;" cx="236.967" cy="236.967" r="236.967" />
        <g>
          <path
            style="fill: #333333;"
            d="M356.671,354.1c-66.226-67.618-174.255-67.337-240.096,0.703c-8.389,8.666,4.827,21.912,13.227,13.227c58.87-60.83,154.386-61.204,213.641-0.703C351.896,375.96,365.116,362.721,356.671,354.1L356.671,354.1z"
          />
          <circle style="fill: #333333;" cx="164.938" cy="155.232" r="37.216" />
          <circle style="fill: #333333;" cx="305.667" cy="155.232" r="37.216" />
        </g>
      </svg>
    </div>
    <div v-if="whichSmileShown === 2" class="smile">
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 473.931 473.931"
        style="enable-background: new 0 0 473.931 473.931;"
        xml:space="preserve"
      >
        <circle style="fill: #ffc10e;" cx="236.966" cy="236.966" r="236.966" />
        <g>
          <circle style="fill: #333333;" cx="164.937" cy="155.231" r="37.216" />
          <circle style="fill: #333333;" cx="305.664" cy="155.231" r="37.216" />
          <path
            style="fill: #333333;"
            d="M105.278,326.581c86.386,0,172.776,0,259.162,0c12.067,0,12.067-18.709,0-18.709c-86.386,0-172.776,0-259.162,0C93.211,307.872,93.211,326.581,105.278,326.581L105.278,326.581z"
          />
        </g>
      </svg>
    </div>
    <div v-if="whichSmileShown === 3" class="smile">
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 473.935 473.935"
        style="enable-background: new 0 0 473.935 473.935;"
        xml:space="preserve"
      >
        <circle style="fill: #ffc10e;" cx="236.967" cy="236.967" r="236.967" />
        <g>
          <circle style="fill: #333333;" cx="164.938" cy="155.232" r="37.216" />
          <circle style="fill: #333333;" cx="305.667" cy="155.232" r="37.216" />
          <path
            style="fill: #333333;"
            d="M343.257,316.862c-59.281,60.329-154.663,59.854-213.45-0.898c-8.4-8.685-21.616,4.561-13.227,13.227c65.769,67.97,173.645,68.34,239.905,0.898C364.944,321.479,351.721,308.245,343.257,316.862L343.257,316.862z"
          />
        </g>
      </svg>
    </div>
    <div v-if="whichSmileShown === 4" class="smile">
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 473.931 473.931"
        style="enable-background: new 0 0 473.931 473.931;"
        xml:space="preserve"
      >
        <circle style="fill: #ffc10e;" cx="236.966" cy="236.966" r="236.966" />
        <g>
          <path
            style="fill: #333333;"
            d="M383.164,237.123c-1.332,80.699-65.514,144.873-146.213,146.206c-80.702,1.332-144.907-67.52-146.206-146.206c-0.198-12.052-18.907-12.071-18.709,0c1.5,90.921,73.993,163.414,164.914,164.914c90.929,1.5,163.455-76.25,164.922-164.914C402.071,225.052,383.362,225.071,383.164,237.123L383.164,237.123z"
          />
          <circle style="fill: #333333;" cx="164.937" cy="155.227" r="37.216" />
          <circle style="fill: #333333;" cx="305.664" cy="155.227" r="37.216" />
        </g>
      </svg>
    </div>
    <div v-if="whichSmileShown === 5" class="smile">
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 473.931 473.931"
        style="enable-background: new 0 0 473.931 473.931;"
        xml:space="preserve"
      >
        <circle style="fill: #ffc10e;" cx="236.966" cy="236.966" r="236.966" />
        <path
          style="fill: #ffffff;"
          d="M81.391,237.123c0,85.911,69.649,155.56,155.56,155.56c85.915,0,155.564-69.649,155.564-155.56L81.391,237.123L81.391,237.123z"
        />
        <g>
          <path
            style="fill: #cccbcb;"
            d="M167.728,237.123c0,46.435,0,92.87,0,139.306c6.024,3.001,12.273,5.609,18.709,7.82c0-49.043,0-98.083,0-147.126H167.728z"
          />
          <path
            style="fill: #cccbcb;"
            d="M283.319,237.123c0,49.511,0,99.018,0,148.529c6.432-2.006,12.666-4.453,18.709-7.24c0-47.098,0-94.191,0-141.289L283.319,237.123L283.319,237.123z"
          />
        </g>
        <g>
          <path
            style="fill: #333333;"
            d="M219.181,158.793c-1.684-31.255-23.992-53.56-55.243-55.243c-31.184-1.68-53.698,26.522-55.243,55.243c-0.651,12.063,18.061,12,18.709,0c2.537-47.09,70.536-47.09,73.069,0C201.12,170.793,219.832,170.856,219.181,158.793L219.181,158.793z"
          />
          <path
            style="fill: #333333;"
            d="M353.985,158.793c-1.684-31.255-23.992-53.56-55.243-55.243c-31.184-1.68-53.694,26.522-55.243,55.243c-0.651,12.063,18.061,12,18.709,0c2.537-47.09,70.532-47.09,73.069,0C335.924,170.793,354.637,170.856,353.985,158.793L353.985,158.793z"
          />
        </g>
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PollQualityAdSmile',
  props: {
    rating: { type: Number, default: 0 },
    maxRating: { type: Number, default: 0 }
  },
  computed: {
    whichSmileShown() {
      const ratingInProcents = (this.rating / this.maxRating) * 100;

      if (ratingInProcents <= 20) {
        return 1;
      }
      if (ratingInProcents > 20 && ratingInProcents <= 40) {
        return 2;
      }
      if (ratingInProcents > 40 && ratingInProcents <= 60) {
        return 3;
      }
      if (ratingInProcents > 60 && ratingInProcents <= 80) {
        return 4;
      }
      if (ratingInProcents > 80) {
        return 5;
      }
      return 0;
    }
  }
};
</script>

<style lang="scss" scoped>
.smile-container {
  /* height: 100%; */
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.smile {
  width: 75%;
}
</style>

<style lang="scss">
.vue-star-rating {
  flex-flow: row wrap;
}
svg.vue-star-rating-star {
  height: 25px;
  width: 25px;
}
</style>
