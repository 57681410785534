<template>
  <div tabindex="-1" class="color-picker">
    <div class="color-picker__trigger__wrapper" @click="toggleAllColorsPalette">
      <div
        v-if="currentValue"
        class="color-picker__trigger"
        :style="{
          width: '42px',
          height: '42px',
          'background-color': currentValue,
          'border-radius': '10px'
        }"
      >
        <div class="color-picker__diagonal__wrapper color-picker--has-children-centered" style="display: none;">
          <div class="color-picker__diagonal"></div>
        </div>
      </div>
      <div
        v-else
        :style="{
          width: '42px',
          height: '42px',
          'border-radius': '10px',
          border: '1px solid rgba(0, 0, 0, 0.15)',
          display: 'flex',
          'justify-content': 'center',
          'align-items': 'center'
        }"
      >
        <span :style="{ 'font-size': '10px', padding: '3px', color: '#7f8a9b', 'text-align': 'center' }">
          {{ noColorText }}
        </span>
      </div>
    </div>
    <div
      v-show="showAllColors"
      class="color-picker__container"
      :style="{
        'background-color': 'rgb(255, 255, 255)',
        padding: '5px',
        'margin-bottom': '5px',
        visibility: 'visible',
        inset
      }"
    >
      <div class="color-picker__wrapper" style="padding-top: 11px; padding-left: 11px; width: 212px;">
        <div
          v-for="color of colors"
          :key="color"
          aria-label="color"
          role="button"
          tabindex="0"
          class="color-picker__swatch"
          :style="{
            width: '42px',
            height: '42px',
            'border-radius': '11px',
            'background-color': color,
            cursor: 'pointer',
            'margin-right': '11px',
            'margin-bottom': '11px'
          }"
          @click="setColor(color)"
        >
          <!---->
          <div v-show="isSelected(color)" class="color-picker__check__wrapper color-picker--has-children-centered">
            <div class="color-picker__check__circle color-picker--has-children-centered">
              <svg version="1.1" role="presentation" width="12" height="12" viewBox="0 0 1792 1792" class="check">
                <path
                  d="M1671 566q0 40-28 68l-724 724-136 136q-28 28-68 28t-68-28l-136-136-362-362q-28-28-28-68t28-68l136-136q28-28 68-28t68 28l294 295 656-657q28-28 68-28t68 28l136 136q28 28 28 68z"
                  class="color-picker__check__path"
                ></path>
              </svg>
            </div>
          </div>
          <!---->
        </div>
      </div>
      <div class="color-picker__fallback__wrapper" style="margin-left: 11px; padding-bottom: 11px; width: 201px;">
        <span class="color-picker__fallback__input--wrapper">
          <input type="text" class="color-picker__fallback__input" v-model="selectedColor" />
        </span>
        <button class="color-picker__fallback__button" @click="submit">
          {{ buttonText }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ColorPalette',
  props: {
    colors: {
      type: Array,
      default: () => ['#1FBC9C', '#111111', '#ffffff', '#ababab', '#FF3D7E', '#3571C8', '#156EE9']
    },
    buttonText: { type: String, default: 'Ok' },
    noColorText: { type: String, default: 'no color' },
    position: { type: String, default: '' },
    defaultValue: { type: String, default: '' }
  },
  data() {
    return {
      showAllColors: false,
      selectedColor: ''
    };
  },
  computed: {
    inset() {
      if (this.position === 'left') {
        return '47px 0px auto auto';
      }
      return '';
    },
    currentValue() {
      return this.selectedColor || this.defaultValue;
    }
  },
  methods: {
    toggleAllColorsPalette() {
      this.showAllColors = !this.showAllColors;
    },
    isSelected(color) {
      return color === this.selectedColor;
    },
    setColor(color) {
      this.selectedColor = color;
      this.submit();
    },
    submit() {
      this.$emit('set-color', this.selectedColor);
      this.toggleAllColorsPalette();
    }
  },
  model: {
    prop: 'value',
    event: 'set-color'
  }
};
</script>

<style lang="scss" scoped>
$width: 50px;
$height: 50px;

.color-picker__swatch {
  position: relative;
  display: inline-block;
  font-size: 0;
  &:hover,
  &:focus {
    opacity: 0.9;
    box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.75);
    outline: none;
  }
  &.color-picker__swatch--border {
    box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.75);
  }
  &.color-picker__swatch--selected {
    box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.75);
  }
  &__label {
    position: absolute;
    left: 0;
    right: 0;
    color: #666;
    font-size: 10px;
    font-weight: bold;
    text-align: center;
  }
  .color-picker__diagonal__wrapper {
    position: absolute;
  }
}

//

.color-picker__check__wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
}
.color-picker__check__circle {
  width: 21px;
  height: 21px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.15);
}
.color-picker__check__path {
  fill: white;
}

//

fieldset[disabled] .color-picker {
  pointer-events: none;
}
.color-picker {
  position: relative;
  display: inline-block;
  outline: none;
}
.color-picker__trigger__wrapper {
  display: inline-block;
  cursor: pointer;
}
.color-picker__trigger {
  &.color-picker--is-empty {
    border: 2px solid #ccc;
  }
  &.color-picker--is-disabled {
    cursor: not-allowed;
  }
}
.color-picker__container {
  box-sizing: content-box;
  &.color-picker--inline {
    font-size: 0;
  }
  &:not(.color-picker--inline) {
    position: absolute;
    display: block;
    overflow: auto;
    border-radius: 0;
    box-shadow: 0 2px 3px rgba(10, 10, 10, 0.2), 0 0 0 1px rgba(10, 10, 10, 0.2);
    z-index: 50;
  }
}
.color-picker__wrapper {
  background-color: inherit;
  box-sizing: content-box;
}
.color-picker__row {
  font-size: 0;
}
.color-picker__fallback__wrapper {
  display: table;
  // justify-content: space-between;
}
.color-picker__fallback__input--wrapper {
  display: table-cell;
  padding-right: 10px;
  width: 100%;
  font-size: 14px;
}
.color-picker__fallback__input {
  width: 100%;
  padding-top: 6px;
  padding-bottom: 6px;
  // border-radius: 5px;
  border: 1px solid #dcdcdc;
  color: #35495e;
  background: #ffffff;
}
.color-picker__fallback__button {
  display: table-cell;
  padding: 6px 15px;
  border: 0;
  cursor: pointer;
  font-weight: bold;
  color: #ffffff;
  background-color: #3571c8;
  // border-radius: 5px;
}
// Transitions
.color-picker-show-hide-enter-active,
.color-picker-show-hide-leave-active {
  transition: all 0.3s ease;
}
.color-picker-show-hide-enter,
.color-picker-show-hide-leave-active {
  opacity: 0;
}
// Shared Styles (Swatch.vue, Check.vue)
.color-picker--has-children-centered {
  display: flex;
  align-items: center;
  justify-content: center;
}
.color-picker__diagonal__wrapper {
  width: 100%;
  height: 100%;
}
.color-picker__diagonal {
  width: 75%;
  height: 75%;
  background: linear-gradient(
    to top right,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0) calc(50% - 2.4px),
    rgba(222, 8, 10, 1) 50%,
    rgba(0, 0, 0, 0) calc(50% + 2.4px),
    rgba(0, 0, 0, 0) 100%
  );
}
</style>
