var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"color-picker",attrs:{"tabindex":"-1"}},[_c('div',{staticClass:"color-picker__trigger__wrapper",on:{"click":_vm.toggleAllColorsPalette}},[(_vm.currentValue)?_c('div',{staticClass:"color-picker__trigger",style:({
        width: '42px',
        height: '42px',
        'background-color': _vm.currentValue,
        'border-radius': '10px'
      })},[_vm._m(0)]):_c('div',{style:({
        width: '42px',
        height: '42px',
        'border-radius': '10px',
        border: '1px solid rgba(0, 0, 0, 0.15)',
        display: 'flex',
        'justify-content': 'center',
        'align-items': 'center'
      })},[_c('span',{style:({ 'font-size': '10px', padding: '3px', color: '#7f8a9b', 'text-align': 'center' })},[_vm._v(" "+_vm._s(_vm.noColorText)+" ")])])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showAllColors),expression:"showAllColors"}],staticClass:"color-picker__container",style:({
      'background-color': 'rgb(255, 255, 255)',
      padding: '5px',
      'margin-bottom': '5px',
      visibility: 'visible',
      inset: _vm.inset
    })},[_c('div',{staticClass:"color-picker__wrapper",staticStyle:{"padding-top":"11px","padding-left":"11px","width":"212px"}},_vm._l((_vm.colors),function(color){return _c('div',{key:color,staticClass:"color-picker__swatch",style:({
          width: '42px',
          height: '42px',
          'border-radius': '11px',
          'background-color': color,
          cursor: 'pointer',
          'margin-right': '11px',
          'margin-bottom': '11px'
        }),attrs:{"aria-label":"color","role":"button","tabindex":"0"},on:{"click":function($event){return _vm.setColor(color)}}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isSelected(color)),expression:"isSelected(color)"}],staticClass:"color-picker__check__wrapper color-picker--has-children-centered"},[_c('div',{staticClass:"color-picker__check__circle color-picker--has-children-centered"},[_c('svg',{staticClass:"check",attrs:{"version":"1.1","role":"presentation","width":"12","height":"12","viewBox":"0 0 1792 1792"}},[_c('path',{staticClass:"color-picker__check__path",attrs:{"d":"M1671 566q0 40-28 68l-724 724-136 136q-28 28-68 28t-68-28l-136-136-362-362q-28-28-28-68t28-68l136-136q28-28 68-28t68 28l294 295 656-657q28-28 68-28t68 28l136 136q28 28 28 68z"}})])])])])}),0),_c('div',{staticClass:"color-picker__fallback__wrapper",staticStyle:{"margin-left":"11px","padding-bottom":"11px","width":"201px"}},[_c('span',{staticClass:"color-picker__fallback__input--wrapper"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.selectedColor),expression:"selectedColor"}],staticClass:"color-picker__fallback__input",attrs:{"type":"text"},domProps:{"value":(_vm.selectedColor)},on:{"input":function($event){if($event.target.composing){ return; }_vm.selectedColor=$event.target.value}}})]),_c('button',{staticClass:"color-picker__fallback__button",on:{"click":_vm.submit}},[_vm._v(" "+_vm._s(_vm.buttonText)+" ")])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"color-picker__diagonal__wrapper color-picker--has-children-centered",staticStyle:{"display":"none"}},[_c('div',{staticClass:"color-picker__diagonal"})])}]

export { render, staticRenderFns }