<template>
  <div>
    <div class="form-group mb-0">
      <h6>
        {{ $t('adsprofile.vendor') }}
        <info v-if="advice" :content="advice" />
      </h6>
      <div class="input-group">
        <input
          type="text"
          name="item"
          class="form-control"
          :disabled="isDisable"
          data-vv-validate-on="change"
          v-model.trim="newItem"
          :class="{ input: true, 'is-danger': errors.has('item') }"
        />
        <span class="input-group-btn">
          <WButton success
            customClass="mb-0"
            @click="addItem(newItem)"
            :disabled="newItem === ''"
          >
            {{ $t('general.add') }}
          </WButton>
        </span>
      </div>
      <span
        v-tooltip.top-center="{
          content: errors.first('item')
        }"
        v-show="errors.has('item')"
        class="help is-danger error_tooltip"
      >
        <i class="fa fa-warning text-error"></i>
      </span>
    </div>
    <p></p>
    <div v-if="!list || list.length === 0">
      <span class="text-muted">{{ $t('adsprofile.vendorNoSpecified') }}</span>
    </div>
    <div v-else>
      <span>{{ $t('adsprofile.vendorSpecified') }}:</span>
    </div>
    <div class="vendors-list" v-if="list && list.length !== 0">
      <!--      <ul class="list-unstyled ml-h">-->
      <span :key="key" v-for="(item, key) in list">
        <span class="ml-h">
          {{ isArray ? item : key }}
          <i class="fa fa-remove text-danger ml-q" @click="removeItem(key)"></i>
        </span>
      </span>
      <!--      </ul>-->
    </div>
    <small class="text-muted">{{ $t('adsprofile.vendorCaption') }}</small>
  </div>
</template>

<script>
import Vue from 'vue';
import Info from '../Universal/info-icon.vue';

export default {
  name: 'AdVendorChoice',
  components: { Info },
  inject: ['$validator'],
  props: {
    list: {
      type: [Object, Array]
    },
    typeList: {
      type: String
    },
    title: {
      type: String
    },
    validate: {
      type: String
    },
    placeholder: {
      type: String
    },
    isDisable: {
      type: Boolean
    },
    advice: {
      type: String
    }
  },
  data() {
    return {
      newItem: ''
    };
  },
  watch: {},
  computed: {
    isArray() {
      return this.typeList === 'array';
    }
  },
  methods: {
    addItem() {
      this.$validator.validateAll({ item: this.newItem }).then((result) => {
        if (result) {
          if (this.isArray && !this.list.includes(this.newItem)) {
            this.list.push(this.newItem);
          } else if (!this.isArray && !this.list[this.newItem]) {
            Vue.set(this.list, this.newItem, true);
          }
          this.newItem = '';
          this.$validator.reset();
        }
      });
    },
    removeItem(item) {
      if (this.isDisable) {
        return;
      }

      if (this.isArray) {
        const index = item;
        this.list.splice(index, 1);
      } else {
        Vue.delete(this.list, item);
      }
    }
  },
  created() {}
};
</script>

<style lang="scss" scoped>
.vendors-list .fa-remove {
  cursor: pointer;
}
</style>
