<template>
  <div v-html="resultHtml"></div>
</template>

<script>
export default {
  name: 'PollQualityStars',
  props: {
    ad: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {};
  },
  methods: {
    createHTMLStatisticForPollQuality(ad) {
      const adStats = ad.statisticsPoll;
      const fullAmount = Object.values(ad.statistics.poll_counter).reduce((prev, curr) => (prev += curr), 0);
      let resultHTML = '';
      adStats.forEach((variant) => {
        const percent = ((+variant[1] / fullAmount) * 100).toFixed(2);
        if (variant[1] !== '0') {
          resultHTML += `<h5><i>${percent}<small>%</small></i> (${variant[1]}) — ${this.createStarsFromNumber(
            variant[0]
          )}</h5>`;
        } else {
          resultHTML += `<h5><i>${percent}<small>%</small></i> (0) — ${this.createStarsFromNumber(variant[0])}</h5>`;
        }
      });
      return resultHTML;
    },
    createStarsFromNumber(number) {
      let resultHTML = '';
      for (let i = 0; i < parseInt(number, 10); i++) {
        resultHTML += "<span class='star-for-poll-quality-in-table h5'> ★ </span>";
      }
      return resultHTML;
    }
  },
  computed: {
    resultHtml() {
      return this.createHTMLStatisticForPollQuality(this.ad);
    }
  }
};
</script>

<style lang="scss">
.star-for-poll-quality-in-table {
  color: #ffa602 !important;
}
</style>
