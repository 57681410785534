<template>
  <div class="preview-mobile">
    <div v-if="isMobile" class="marvel-device iphone8 silver d-flex m-auto">
      <div class="top-bar"></div>
      <div class="sleep"></div>
      <div class="volume"></div>
      <div class="camera"></div>
      <div class="sensor"></div>
      <div class="speaker"></div>
      <div class="screen" :class="{ 'no-scroll-bar': isScrollable, 'overflowY-hidden': !isScrollable }">
        <slot name="mobile-screen"></slot>
      </div>
      <div class="home"></div>
      <div class="bottom-bar"></div>
    </div>
    <div v-else class="marvel-device macbook">
      <div class="top-bar"></div>
      <div class="camera"></div>
      <div class="screen" :class="{ 'no-scroll-bar': isScrollable, 'overflowY-hidden': !isScrollable }">
        <slot name="laptop-screen"></slot>
      </div>
      <div class="bottom-bar"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PreviewMobileOrLaptop',
  props: {
    type: { type: String, default: 'mobile' },
    fileUrl: { type: String, default: '' },
    isScrollable: { type: Boolean, default: false }
  },
  computed: {
    isMobile() {
      switch (this.type) {
        case 'laptop':
          return false;
        default:
          return true;
      }
    }
  },
  watch: {
    fileUrl() {
      this.$forceUpdate();
    }
  }
};
</script>
<style lang="scss" scoped>
// 0.75 - scaling const for iphone
// 0.45 - scaling const for macbook
div.preview-mobile {
  .marvel-device {
    display: inline-block;
    position: relative;
    box-sizing: content-box !important;

    .screen {
      width: 100%;
      position: relative;
      height: 100%;
      z-index: 3;
      background: white;
      overflow-x: hidden;
      overflow-y: auto;
      display: block;
      border-radius: calc(1px * 0.75);
      box-shadow: 0 0 0 calc(3px * 0.75) #111;

      //NOTE: icons from http://www.iconarchive.com/show/free-multimedia-icons-by-designbolts/Photo-icon.html -->

      .preview {
        width: 100%;
        height: 100%;
        background-position: center center;
        background-size: contain;
        background-repeat: no-repeat;
      }

      .preview.mobile-image {
        background-size: contain;
        background-image: url(https://storage.cloud.google.com/wimark-one/images/previews/Photo-icon.png);
      }
      .preview.mobile-video {
        background-size: contain;
        background-image: url(https://storage.cloud.google.com/wimark-one/images/previews/Film-icon.png);
      }
    }

    .top-bar,
    .bottom-bar {
      height: calc(3px * 0.75);
      background: black;
      width: 100%;
      display: block;
    }

    .middle-bar {
      width: calc(3px * 0.75);
      height: calc(4px * 0.75);
      top: calc(0px * 0.75);
      left: calc(90px * 0.75);
      background: black;
      position: absolute;
    }

    &.iphone8 {
      width: calc(375px * 0.75);
      height: calc(667px * 0.75);
      padding: calc(105px * 0.75) calc(24px * 0.75);
      background: #d9dbdc;
      border-radius: calc(56px * 0.75);
      box-shadow: inset 0 0 calc(3px * 0.75) 0 rgba(0, 0, 0, 0.2);

      &:before {
        width: calc(100% - calc(12px * 0.75));
        height: calc(100% - calc(12px * 0.75));
        position: absolute;
        top: calc(6px * 0.75);
        content: '';
        left: calc(6px * 0.75);
        border-radius: calc(50px * 0.75);
        background: #f8f8f8;
        z-index: 1;
      }

      &:after {
        width: calc(100% - calc(16px * 0.75));
        height: calc(100% - calc(16px * 0.75));
        position: absolute;
        top: calc(8px * 0.75);
        content: '';
        left: calc(8px * 0.75);
        border-radius: calc(48px * 0.75);
        box-shadow: inset 0 0 calc(3px * 0.75) 0 rgba(0, 0, 0, 0.1), inset 0 0 calc(6px * 0.75) calc(3px * 0.75) #ffffff;
        z-index: 2;
      }

      .home {
        border-radius: 100%;
        width: calc(68px * 0.75);
        height: calc(68px * 0.75);
        position: absolute;
        left: 50%;
        margin-left: calc(-34px * 0.75);
        bottom: calc(22px * 0.75);
        z-index: 3;
        background: rgb(48, 50, 51);
        background: linear-gradient(
          135deg,
          rgba(48, 50, 51, 1) 0%,
          rgba(181, 183, 185, 1) 50%,
          rgba(240, 242, 242, 1) 69%,
          rgba(48, 50, 51, 1) 100%
        );

        &:before {
          background: #f8f8f8;
          position: absolute;
          content: '';
          border-radius: 100%;
          width: calc(100% - calc(8px * 0.75));
          height: calc(100% - calc(8px * 0.75));
          top: calc(4px * 0.75);
          left: calc(4px * 0.75);
        }
      }

      .top-bar {
        height: calc(14px * 0.75);
        background: #bfbfc0;
        position: absolute;
        top: calc(68px * 0.75);
        left: 0;
      }

      .bottom-bar {
        height: calc(14px * 0.75);
        background: #bfbfc0;
        position: absolute;
        bottom: calc(68px * 0.75);
        left: 0;
      }

      .sleep {
        position: absolute;
        top: calc(190px * 0.75);
        right: calc(-4px * 0.75);
        width: calc(4px * 0.75);
        height: calc(66px * 0.75);
        border-radius: calc(0px * 0.75) calc(2px * 0.75) calc(2px * 0.75) calc(0px * 0.75);
        background: #d9dbdc;
      }

      .volume {
        position: absolute;
        left: calc(-4px * 0.75);
        top: calc(188px * 0.75);
        z-index: 0;
        height: calc(66px * 0.75);
        width: calc(4px * 0.75);
        border-radius: calc(2px * 0.75) calc(0px * 0.75) calc(0px * 0.75) calc(2px * 0.75);
        background: #d9dbdc;

        &:before {
          position: absolute;
          left: calc(2px * 0.75);
          top: calc(-78px * 0.75);
          height: calc(40px * 0.75);
          width: calc(2px * 0.75);
          border-radius: calc(2px * 0.75) calc(0px * 0.75) calc(0px * 0.75) calc(2px * 0.75);
          background: inherit;
          content: '';
          display: block;
        }

        &:after {
          position: absolute;
          left: calc(0px * 0.75);
          top: calc(82px * 0.75);
          height: calc(66px * 0.75);
          width: calc(4px * 0.75);
          border-radius: calc(2px * 0.75) calc(0px * 0.75) calc(0px * 0.75) calc(2px * 0.75);
          background: inherit;
          content: '';
          display: block;
        }
      }

      .camera {
        background: #3c3d3d;
        width: calc(12px * 0.75);
        height: calc(12px * 0.75);
        position: absolute;
        top: calc(24px * 0.75);
        left: 50%;
        margin-left: calc(-6px * 0.75);
        border-radius: 100%;
        z-index: 3;
      }

      .sensor {
        background: #3c3d3d;
        width: calc(16px * 0.75);
        height: calc(16px * 0.75);
        position: absolute;
        top: calc(49px * 0.75);
        left: calc(134px * 0.75);
        z-index: 3;
        border-radius: 100%;
      }

      .speaker {
        background: #292728;
        width: calc(70px * 0.75);
        height: calc(6px * 0.75);
        position: absolute;
        top: calc(54px * 0.75);
        left: 50%;
        margin-left: calc(-35px * 0.75);
        border-radius: calc(6px * 0.75);
        z-index: 3;
      }

      &.gold {
        background: #f9e7d3;

        .top-bar,
        .bottom-bar {
          background: white;
        }

        .sleep,
        .volume {
          background: #f9e7d3;
        }

        .home {
          background: rgb(206, 187, 169);
          background: linear-gradient(
            135deg,
            rgba(206, 187, 169, 1) 0%,
            rgba(249, 231, 211, 1) 50%,
            rgba(206, 187, 169, 1) 100%
          );
        }
      }

      &.black {
        background: #464646;
        box-shadow: inset 0 0 calc(3px * 0.75) 0 rgba(0, 0, 0, 0.75);

        &:before {
          background: #080808;
        }

        &:after {
          box-shadow: inset 0 0 calc(3px * 0.75) 0 rgba(0, 0, 0, 0.1),
            inset 0 0 calc(6px * 0.75) calc(3px * 0.75) #212121;
        }

        .top-bar,
        .bottom-bar {
          background: #212121;
        }

        .volume,
        .sleep {
          background: #464646;
        }

        .camera {
          background: #080808;
        }

        .home {
          background: rgb(8, 8, 8);
          background: linear-gradient(135deg, rgba(8, 8, 8, 1) 0%, rgba(70, 70, 70, 1) 50%, rgba(8, 8, 8, 1) 100%);

          &:before {
            background: #080808;
          }
        }
      }

      &.landscape {
        padding: calc(24px * 0.75) calc(105px * 0.75);
        height: calc(375px * 0.75);
        width: calc(667px * 0.75);

        .sleep {
          top: 100%;
          border-radius: calc(0px * 0.75) calc(0px * 0.75) calc(2px * 0.75) calc(2px * 0.75);
          right: calc(190px * 0.75);
          height: calc(4px * 0.75);
          width: calc(66px * 0.75);
        }

        .volume {
          width: calc(66px * 0.75);
          height: calc(4px * 0.75);
          top: calc(-4px * 0.75);
          left: calc(100% - calc(188px * 0.75) - calc(66px * 0.75));
          border-radius: calc(2px * 0.75) calc(2px * 0.75) calc(0px * 0.75) calc(0px * 0.75);

          &:before {
            width: calc(40px * 0.75);
            height: calc(2px * 0.75);
            top: calc(2px * 0.75);
            right: calc(-78px * 0.75);
            left: auto;
            border-radius: calc(2px * 0.75) calc(2px * 0.75) calc(0px * 0.75) calc(0px * 0.75);
          }

          &:after {
            left: calc(-82px * 0.75);
            width: calc(66px * 0.75);
            height: calc(4px * 0.75);
            top: 0;
            border-radius: calc(2px * 0.75) calc(2px * 0.75) calc(0px * 0.75) calc(0px * 0.75);
          }
        }

        .top-bar {
          width: calc(14px * 0.75);
          height: 100%;
          left: calc(100% - calc(68px * 0.75) - calc(14px * 0.75));
          top: 0;
        }

        .bottom-bar {
          width: calc(14px * 0.75);
          height: 100%;
          left: calc(68px * 0.75);
          top: 0;
        }

        .home {
          top: 50%;
          margin-top: calc(-34px * 0.75);
          margin-left: 0;
          left: calc(22px * 0.75);
        }

        .sensor {
          top: calc(134px * 0.75);
          left: calc(100% - calc(49px * 0.75) - calc(16px * 0.75));
        }

        .speaker {
          height: calc(70px * 0.75);
          width: calc(6px * 0.75);
          left: calc(100% - calc(54px * 0.75) - calc(6px * 0.75));
          top: 50%;
          margin-left: calc(0px * 0.75);
          margin-top: calc(-35px * 0.75);
        }

        .camera {
          left: calc(100% - calc(32px * 0.75));
          top: 50%;
          margin-left: calc(0px * 0.75);
          margin-top: calc(-5px * 0.75);
        }
      }
    }
    &.macbook {
      width: calc(960px * 0.45);
      height: calc(600px * 0.45);
      padding: calc(44px * 0.45) calc(44px * 0.45) calc(76px * 0.45);
      margin: 0 auto;
      background: #bebebe;
      border-radius: calc(34px * 0.45);

      &:before {
        width: calc(100% - calc(8px * 0.45));
        height: calc(100% - calc(8px * 0.45));
        position: absolute;
        content: '';
        display: block;
        top: calc(4px * 0.45);
        left: calc(4px * 0.45);
        border-radius: calc(30px * 0.45);
        background: #1e1e1e;
      }

      .top-bar {
        width: calc(100% + 2 * calc(70px * 0.45));
        height: calc(40px * 0.45);
        position: absolute;
        content: '';
        display: block;
        top: calc(680px * 0.45);
        left: calc(-70px * 0.45);
        border-bottom-left-radius: calc(90px * 0.45) calc(18px * 0.45);
        border-bottom-right-radius: calc(90px * 0.45) calc(18px * 0.45);
        background: #bebebe;
        box-shadow: inset calc(0px * 0.45) calc(-4px * 0.45) calc(13px * 0.45) calc(3px * 0.45) rgba(34, 34, 34, 0.75);

        &:before {
          width: 100%;
          height: calc(24px * 0.45);
          content: '';
          display: block;
          top: 0;
          left: 0;
          background: #f0f0f0;
          border-bottom: calc(2px * 0.45) solid #aaa;
          border-radius: calc(5px * 0.45);
          position: relative;
        }

        &:after {
          width: 16%;
          height: calc(14px * 0.45);
          content: '';
          display: block;
          top: 0;
          background: #ddd;
          position: absolute;
          margin-left: auto;
          margin-right: auto;
          left: 0;
          right: 0;
          border-radius: 0 0 calc(20px * 0.45) calc(20px * 0.45);
          box-shadow: inset calc(0px * 0.45) calc(-3px * 0.45) calc(10px * 0.45) #999;
        }
      }

      .bottom-bar {
        background: transparent;
        width: calc(100% + 2 * calc(70px * 0.45));
        height: calc(26px * 0.45);
        position: absolute;
        content: '';
        display: block;
        top: calc(680px * 0.45);
        left: calc(-70px * 0.45);

        &:before,
        &:after {
          height: calc(100% - calc(2px * 0.45));
          width: calc(80px * 0.45);
          content: '';
          display: block;
          top: 0;

          position: absolute;
        }

        &:before {
          left: 0;
          background: #f0f0f0;
          background: linear-gradient(
            to right,
            #747474 0%,
            #c3c3c3 5%,
            #ebebeb 14%,
            #979797 41%,
            #f0f0f0 80%,
            #f0f0f0 100%,
            #f0f0f0 100%
          );
        }

        &:after {
          right: 0;
          background: #f0f0f0;
          background: linear-gradient(
            to right,
            #f0f0f0 0%,
            #f0f0f0 0%,
            #f0f0f0 20%,
            #979797 59%,
            #ebebeb 86%,
            #c3c3c3 95%,
            #747474 100%
          );
        }
      }

      .camera {
        background: #3c3d3d;
        width: calc(10px * 0.45);
        height: calc(10px * 0.45);
        position: absolute;
        top: calc(20px * 0.45);
        left: 50%;
        margin-left: calc(-5px * 0.45);
        border-radius: 100%;
      }

      .home {
        display: none;
      }
    }
  }
}
</style>
