<template>
  <div>
    <div class="form-group mb-0">
      <label for="counter-of-stars" class="counter-of-stars">{{ $t('adsprofile.pollQualityVariants') }}</label>
      <span
        v-tooltip.top-center="{ content: this.$t('adsprofile.poolQualityVariantsValidate') }"
        v-show="errors.has('counter-of-stars')"
        class="help is-danger error_tooltip"
      >
        <i class="fa fa-warning text-error"></i>
      </span>
      <div class="input-group">
        <input
          id="counter-of-stars"
          name="counter-of-stars"
          type="number"
          class="form-control"
          v-validate="'min_value:1|max_value:5'"
          data-vv-validate-on="input"
          v-model="starCounterInputed"
          :class="{ input: true, 'is-danger': errors.has('counter-of-stars') }"
        />
        <span class="input-group-btn">
          <WButton success
            customClass="mb-0"
            @click="updateStarsCounter"
            :disabled="errors.has('counter-of-stars')"
          >
            {{ $t('adsprofile.poolQualityUpdateVariants') }}
          </WButton>
        </span>
      </div>
    </div>

    <div>
      <span class="star" v-for="star in list">
        ★
      </span>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';

export default {
  name: 'PollQualityVariants',
  inject: ['$validator'],
  props: {
    list: {
      type: [Object, Array]
    },
    typeList: {
      type: String
    },
    title: {
      type: String
    },
    validate: {
      type: String
    },
    placeholder: {
      type: String
    },
    isDisable: {
      type: Boolean
    }
  },
  data() {
    return {
      newItem: '',
      starCounter: 0,
      starCounterInputed: 0
    };
  },
  watch: {
    starsForPollVariants() {
      // console.log('change!');
      this.list.splice(0, this.list.length);
      for (const item of this.starsForPollVariants) {
        this.list.push(item);
      }
    }
  },
  computed: {
    isArray() {
      return this.typeList === 'array';
    },
    starsForPollVariants() {
      const variantsArr = [];
      for (let i = 0; i < parseInt(this.starCounter); i++) {
        // console.log('nbsw');
        variantsArr.push(`${i + 1}`);
      }
      return variantsArr;
    },
    starCounterFromProps() {
      return this.list.length;
    }
  },
  methods: {
    updateStarsCounter() {
      this.starCounter = this.starCounterInputed;
    }
  },
  mounted() {
    this.starCounter = this.starCounterFromProps;
    if (this.starCounterFromProps < 1) {
      this.starCounterInputed = 1;
      this.starCounter = 1;
    } else {
      this.starCounterInputed = this.starCounterFromProps;
      this.starCounter = this.starCounterFromProps;
    }
  }
};
</script>

<style lang="scss" scoped>
.star {
  font-size: 3em;
  color: #ffa602;
}
.star:hover {
  font-size: 3em;
  color: rgba(255, 172, 2, 0.85);
}
</style>
